<template>
  <div style="width: 100%; height: calc(100vh - 10px);">
    <div class="pa-2">
      <v-switch
        v-model="filterUnreadMessages"
        :label="str['filter_conversations_with_new_messages']"
        color="secondary"
        class="mt-0"
        style="width: fit-content;"
        @change="onChangeFilterUnreadMessages"
        hide-details
      />
    </div>
    <div ref="talkjs" style="width: 100%; height: calc(100% - 40px);">
      <i class="text-center">{{ str['processing'] }}</i>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import Talk from 'talkjs'
  import Api from '@/services/Api'
  import Utils from '@/services/Utils'

  export default {
    name: 'talksjs',
    props: ['refresh', 'open'],
    data() {
      const user = Utils.getUser()
      const ptTag = Object.keys(PTS_IDS).find(k => PTS_IDS[k] === user.pt_id)?.toLowerCase()
      return {
        user: user,
        str: window.strings,
        ptTag: ptTag,
        userId: (axios.defaults.dev ? 'dev' : 'prod') + '_' + ptTag + '_' + user.pt_id,
        filterUnreadMessages: false,
        inbox: null,
        appId: null,
        token: null,
      }
    },
    async mounted() {
      this.init()
    },
    methods: {
      init: async function () {
        const self = this
        await Talk.ready

        Api.getTalkjsToken({
          id: this.userId
        }, function (response) {
          if (response.success) {
            self.appId = response.data.app_id
            self.token = response.data.token
            self.draw()
          } else {
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
      draw: function () {
        const isEmployee = Utils.isEmployee(this.user.type)
        const filterEmployee = isEmployee && this.user && this.user.configurations && this.user.configurations.employees && this.user.configurations.employees.assign_clients ? true : false
        window.talkjsPtUser = isEmployee ? new Talk.User(this.userId) : new Talk.User({
          id: this.userId,
          name: this.user.name,
          welcomeMessage: null,
          locale: 'pt-BR',
          role: this.ptTag + '_admin'
        })
        window.talkjsSession = new Talk.Session({
          appId: this.appId,
          me: window.talkjsPtUser,
          locale: 'pt-BR',
          token: this.token
        })

        window.talkjsSession.unreads.onChange(async (conversations) => {
          let totalUnreadMessages = 0
          if (filterEmployee) {
            conversations = conversations.filter((c) => {
              return c.conversation && c.conversation.custom && c.conversation.custom['employee_' + this.user.id]
            })
          }
          conversations.forEach(conversation => {
            totalUnreadMessages += conversation.unreadMessageCount;
          })

          this.refresh(totalUnreadMessages)
        })

        this.inbox = window.talkjsSession.createInbox({
          selected: null,
          locale: 'pt-BR'
        })

        const data = {}
        if (filterEmployee) {
          data.custom = {
            ['employee_' + this.user.id]: 'exists'
          }
        }
        if (this.filterUnreadMessages) {
          data.hasUnreadMessages = true
        }
        this.inbox.mount(this.$refs.talkjs)
        this.inbox.setFeedFilter(data)
      },
      openClient: function (clientDbId) {
        const self = this
        this.$isLoading(true)
        Api.getUserWithDbId({
          id: clientDbId,
          fields: ['id', 'name', 'chat_token', 'employee1', 'employee2']
        }, function (response) {
          self.$isLoading(false)
          if (response.success && response.data && response.data[0]) {
            const clientData = response.data[0]
            const client = new Talk.User({
              id: (axios.defaults.dev ? 'dev' : 'prod') + '_' + self.ptTag + '_' + clientData.id,
              name: clientData.name,
              locale: 'pt-BR',
              role: self.ptTag + '_client'
            })
            const conversation = window.talkjsSession.getOrCreateConversation(
              Talk.oneOnOneId(window.talkjsPtUser, client)
            )
            const custom = {
              customer_token: clientData.chat_token ? clientData.chat_token : ''
            }
            if (clientData.employee1) {
              custom['employee_' + clientData.employee1] = 'true'
            }
            if (clientData.employee2) {
              custom['employee_' + clientData.employee2] = 'true'
            }
            conversation.setAttributes({
              custom: custom
            })
            conversation.setParticipant(window.talkjsPtUser)
            conversation.setParticipant(client)
            self.inbox.select(conversation)
            self.open()
          } else {
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
      changeStatus: function (value) {
        this.inbox.setPresence({ visible: value })
      },
      onChangeFilterUnreadMessages: function () {
        this.inbox.destroy()
        this.draw()
      },
    }
  }
</script>